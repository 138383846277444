<template>
  <mdb-container>
    <div class="text-right">
      <router-link to="/admin/youtube-registration">
        <mdb-btn outline="info" size="md" class="my-3" icon="pen">
          등록
        </mdb-btn>
      </router-link>
    </div>
    <mdb-card>
      <h3 class="card-header unique-color white-text text-center font-weight-bold text-uppercase py-2 ">영상 리스트</h3>
      <mdb-tbl btn responsive striped>
        <mdb-tbl-head>
          <tr>
            <th>#</th>
            <th>ID</th>
            <th>타이틀</th>
            <th>Video ID</th>
<!--            <th>노출유무</th>-->
            <th>액션</th>
          </tr>
        </mdb-tbl-head>
        <mdb-tbl-body>
          <TrTag :youtube="youtubes[i]"  v-for="(a,i) in youtubes" :key="i" @update="onDataReset"/>
        </mdb-tbl-body>
      </mdb-tbl>
    </mdb-card>
  </mdb-container>
</template>
<script>
import {mdbContainer, mdbTbl, mdbTblHead, mdbTblBody, mdbCard, mdbBtn} from 'mdbvue';
import firebase from 'firebase/app';
import TrTag from "./TrTag";

export default {
  name: 'YoutubeManageList',
  data() {
    return {
      youtubes: [],
    }
  },
  components: {
    mdbContainer,
    TrTag,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    mdbCard,
    mdbBtn,
  },
  mounted() {
    this.onLoadAllArrayData();
  },
  methods: {
    onDataReset() {
      const self = this;
      self.youtubes = [];
      self.onLoadAllArrayData();
    },
    onLoadAllArrayData() {
      const db = firebase.firestore();
      const self = this;
      db.collection("Youtube").orderBy('order_number').get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              const _data = doc.data();
              // console.log(_data);
              _data["id"] = doc.id;
              self.youtubes.push(_data);
            });
          })
          .catch(function (e) {
            console.log(e);
          });
    },
  },
}
</script>

<style scoped>

</style>