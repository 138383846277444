<template>
  <tr>
    <td>{{ youtube.order_number }}</td>
    <td>{{ youtube.id }}</td>
    <td>{{ youtube.title }}</td>
    <td> {{ youtube.video_id }}</td>
<!--    <td>-->
<!--      <mdb-btn outline="info" darkWaves rounded size="sm" v-if="youtube.show">{{ youtube.show }}</mdb-btn>-->
<!--      <mdb-btn outline="danger" darkWaves rounded size="sm" v-if="!youtube.show">{{ youtube.show }}</mdb-btn>-->
<!--    </td>-->
    <td>
      <router-link :to="{name : 'admin-youtube-update', query: {id: youtube.id}}">
        <button type="button" class="btn btn-indigo btn-sm m-0  m-1">수정</button>
      </router-link>
      <button type="button" class="btn btn-danger btn-sm m-0 m-1" @click="onDeleteData(youtube.id)">삭제</button>
    </td>
  </tr>
</template>
<script>
import firebase from "firebase/app";

export default {
  name: "TrTag",
  components: {
  },
  props: {
    youtube: Object,
    count: Number
  },
  methods: {
    onDeleteData(id) {
      const db = firebase.firestore();
      db.collection("Youtube")
          .doc(id)
          .delete()
          .then(() => {
            console.log("Document successfully deleted!");
            this.$emit('update');
          })
          .catch((error) => {
            console.error("Error removing document: ", error);
          });
    },
    onUpdateData() {

    },
  }
}
</script>

<style scoped>

</style>